.react-calendar {
  max-width: 100%;
  background-color: #18191b;
  line-height: 1.125em;
  border-radius: 8px;
}
.react-calendar__viewContainer {
  padding: 24px 22px 38px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;

  & > * {
    width: 50%;
  }
  & > :first-child {
    margin-right: 45px;
  }
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2a2a2a;
  color: #B6B6B6;
  border-radius: 8px 8px 0 0;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 24px;
}
.react-calendar__navigation__label {
  font-weight: bold;
  line-height: 24px;
}
.react-calendar__navigation button[disabled] {
  color: #6B6B6B;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #6B6B6B;
  line-height: 17px;
}
.react-calendar__month-view__weekdays__weekday {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 10px;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__year-view,
.react-calendar__decade-view,
.react-calendar__century-view {
  flex-grow: 1;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  color: #6B6B6B;
  border: 1px solid #6B6B6B;
}
.react-calendar__tile:disabled {
  color: #242424;
  border: 1px solid #242424
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border: 1px solid #71f697;
}
.react-calendar__tile--now {
  font-weight: bold;
}
.react-calendar__tile--range,
.react-calendar--selectRange .react-calendar__tile--hover {
  &:enabled:not(.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd, .react-calendar__tile--hoverStart, .react-calendar__tile--hoverEnd) {
    background-color: #242424;
    color: #B6B6B6;
    border: 1px solid #242424
  }
}
.react-calendar__tile--hasActive:not(.react-calendar__tile--range),
.react-calendar__tile--active:not(.react-calendar__tile--range),
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar--selectRange .react-calendar__tile--rangeBothEnds {
  background-color: #71f697;
  border: 1px solid #71f697;
  color: #18191b;
}

.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  display: none !important;
}