.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: #242424;
  color: #6B6B6B;
  border: 1px solid #6B6B6B;
  border-radius: 6px;
  padding: 6px 15px 7px;
  font-size: 14px;

  &:hover {
    border: 1px solid #71f697;
  }
}
.react-date-picker--disabled .react-date-picker__wrapper {
  background-color: #18191B;
  color: #242424;
  border: 1px solid transparent;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 0 2px;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;

  &:focus {
    color: #B6B6B6;
  }
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding-left: 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button .react-date-picker__button__icon,
.react-date-picker__button .react-date-picker__button__icon {
  stroke: #6B6B6B;
  width: 14px;
  height: 14px;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #B6B6B6;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #242424;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  max-width: 100vw;
  position: absolute;
  left: 0;
  z-index: 2;
  transform: translateY(15px);

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -4px;
    left: 46px;
    width: 20px;
    height: 20px;
    background-color: #2a2a2a;
    transform: rotate(45deg);
  }
}
.react-date-picker__calendar--closed {
  display: none;
}
